import './index.js';
import './owlCarousel.rows.js';

function getURLVar(key) {
	var value = [];

	var query = String(document.location).split('?');

	if (query[1]) {
		var part = query[1].split('&');

		for (i = 0; i < part.length; i++) {
			var data = part[i].split('=');

			if (data[0] && data[1]) {
				value[data[0]] = data[1];
			}
		}

		if (value[key]) {
			return value[key];
		} else {
			return '';
		}
	}
}

function getRandomColor() {
  var letters = '0123456789ABCDEF';
  var color = '#';
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

function callLeaveMessage() {
	event.preventDefault();
	$( ".mylivechat_collapsed" ).trigger( "click" );
}


//setup array of scripts and an index to keep track of where we are in the process
var scripts = ['catalog/view/javascript/jquery.instagramFeed.min.js'],
    index   = 0;

//setup a function that loads a single script
function load_script() {

    //make sure the current index is still a part of the array
    if (index < scripts.length) {

        //get the script at the current index
        $.getScript(scripts[index], function () {

            //once the script is loaded, increase the index and attempt to load the next script
            //console.log('Loaded: ' + scripts[index]);
            index++;
            load_script();
        });
    }
}

$(document).ready(function() {

	$(".input-group .button-plus").on("click", function(n) {
		var t, a;
		(n = n).preventDefault(),
			t = $(n.target).data("field"),
			n = $(n.target).closest("div"),
			a = parseInt(n.find("input[name=" + t + "]").val(), 10),
			isNaN(a) ? n.find("input[name=" + t + "]").val(0) : n.find("input[name=" + t + "]").val(a + 1)

	});

		$(".input-group .button-minus").on("click", function(n) {
			var t, a;
			(n = n).preventDefault(),
				t = $(n.target).data("field"),
				n = $(n.target).closest("div"),
				inputValue = parseInt(n.find("input[name=" + t + "]").val(), 10);
    
				if (!isNaN(inputValue) && inputValue > 1) {
					n.find("input[name=" + t + "]").val(inputValue - 1);
				} else {
					n.find("input[name=" + t + "]").val(1); // Ensure the minimum value is 1
				}
		});
		
	if (document.getElementById('showMoreButton')) {
		document.getElementById('showMoreButton').addEventListener('click', function (e) {
			e.preventDefault();
			var dataTextId = this.getAttribute('data-text'); // Get the data-text attribute value
			var dataText = document.querySelector('span[data-text="' + dataTextId + '"]');
			if (dataText.style.display === 'none') {
				dataText.style.display = 'inline'; // Show the hidden text
				this.innerText = 'Skrýt popisek';
			} else {
				dataText.style.display = 'none'; // Hide the hidden text
				this.innerText = 'Ukaž celý popisek';
			}
		});
	}

	//Filter menu - accordion menu
	var Accordion = function(el, multiple) {
		this.el = el || {};
		this.multiple = multiple || true;

		// Variables privadas
		var links = this.el.find('.dropdown-item');
		// Evento
		links.on('click', {el: this.el, multiple: this.multiple}, this.dropdown)
	}

	Accordion.prototype.dropdown = function(e) {
		var $el = e.data.el;
		$this = $(this);
		$next = $this.next();

		$next.slideToggle();
		$this.parent().toggleClass('open');
		$this.find('.fas').toggleClass('fa-chevron-up');

		if (!e.data.multiple) {
			$el.find('.dropdown-menu').not($next).slideUp().parent().removeClass('open');
		};
	}

	var accordion = new Accordion($('#accordion'), false);

	//Filter menu - accordion menu

	// megamenu + header - show border

	$("#mainNav li.dropdown").first().on({
		mouseenter: function () {
			$(".header-container.header-row").css('box-shadow', '0 4px 2px -2px rgb(193 193 193 / 90%)');
		},
		mouseleave: function () {
			$(".header-container.header-row").css('box-shadow', 'unset')
		}
	});


	//megamenu + header


	//load more products in category button


	//load more products in category button

	$('#banner-separator').owlCarousel({
		items: 1,
		loop: false,
		autoHeight: true,
		autoplay:false,
		autoplayTimeout: 10000,
		singleItem: true,
		navigation: false,
		pagination: false,
		transitionStyle: 'fade'
	});

	let subMenuWidth = $(".main-content.home .container").width()-$(".megamenu-wrapper").width();
	//console.log(subMenuWidth);

	$(".vertical ul.megamenu > li > .sub-menu").css({
		'width': subMenuWidth + "px"
	});

	//product catalog - different length of product description - height fix
	var maxHeight = -1;
	$('div.product span.description').each(function() {

		maxHeight = maxHeight > $(this).height() ? maxHeight :     $(this).height();
	});

	$('div.product span.description').each(function() {
		$(this).height(maxHeight);
	});
	//product catalog - different length of product description - height fix

	//blog catalog - different length of product description - height fix
	var maxHeightBlog = -1;
	$('.blog-posts .post-content').each(function() {
		maxHeightBlog = maxHeightBlog > $(this).height() ? maxHeightBlog :     $(this).height();
	});

	$('.blog-posts .post-content').each(function() {
		$(this).height(maxHeightBlog);
	});

	//blog catalog - different length of product description - height fix


 let cartButtonWidth = $('.product-action .product-btn.btn-add-cart').outerWidth();
	$('.product-action.quantity-input').each(function() {
		console.log(this);
		$(this).width(cartButtonWidth);
	});


$(".quantity .add-action").click(function(){
	if( $(this).hasClass('add-up') ) {
			$("[name=quantity]",'.quantity').val( parseInt($("[name=quantity]",'.quantity').val()) + 1 );
	}else {
		if( parseInt($("[name=quantity]",'.quantity').val())  > 1 ) {
			$("input",'.quantity').val( parseInt($("[name=quantity]",'.quantity').val()) - 1 );
		}
	}
});



$("#mini-cart").on("click", function(event){  // ***
	console.log(event.target);
    if ($(event.target.parentElement).is("button")) {
        return;
    }
		event.preventDefault();
		var link = $(this).attr("data-link");
		window.location = link
});

$(".server").on('click', ':not(.delete-server)', function (e) {
     e.stopPropagation()
     // Show edit dialog
});



	var labelColors = ['#e27c7c','#9361e4','#3f4ed8'];
	var i = 0;
	var color;
	$(".icons-list .onsale1.text").each(function() {
		if (i == labelColors.length)
			i = 0;

		color = labelColors[i];
		$(this).css('background', color);
		i++;
	});



	// Highlight any found errors
	$('.text-danger').each(function() {
		var element = $(this).parent().parent();

		if (element.hasClass('form-group')) {
			element.addClass('has-error');
		}
	});

	// Currency
	$('#form-currency .currency-select').on('click', function(e) {
		e.preventDefault();

		$('#form-currency input[name=\'code\']').val($(this).attr('name'));

		$('#form-currency').submit();
	});

	// Language
	$('#form-language .language-select').on('click', function(e) {
		e.preventDefault();

		$('#form-language input[name=\'code\']').val($(this).attr('name'));

		$('#form-language').submit();
	});

	/* Search */
	$('#headerTopSearchDropdown').parent().find('button').on('click', function() {

		var url = $('base').attr('href') + 'index.php?route=product/search';

		var value = $('#headerTopSearchDropdown input[name=\'search\']').val();

		if (value) {
			url += '&search=' + encodeURIComponent(value);
		}

		location = url;
	});

	$('#search input[name=\'search\']').on('keydown', function(e) {
		if (e.keyCode == 13) {
			$('header #search input[name=\'search\']').parent().find('button').trigger('click');
		}
	});

	// Menu
	$('#menu .dropdown-menu').each(function() {
		var menu = $('#menu').offset();
		var dropdown = $(this).parent().offset();

		var i = (dropdown.left + $(this).outerWidth()) - (menu.left + $('#menu').outerWidth());

		if (i > 0) {
			$(this).css('margin-left', '-' + (i + 10) + 'px');
		}
	});


	function display(view) {

		if (view == 'list') {
			$('.product-grid').removeClass("active");
			$('.product-list').addClass("active");

			$('.display').html('<button id="grid" rel="tooltip" title="Grid" onclick="display(\'grid\');"></button> <button class="active" id="list" rel="tooltip" title="List" onclick="display(\'list\');"></button>');

			localStorage.setItem('display', 'list');
		} else {

			$('.product-grid').addClass("active");
			$('.product-list').removeClass("active");

			$('.display').html('<button class="active" id="grid" rel="tooltip" title="Grid" onclick="display(\'grid\');"></button> <button id="list" rel="tooltip" title="List" onclick="display(\'list\');"></button>');

			localStorage.setItem('display', 'grid');
		}
	}

	if (localStorage.getItem('display') == 'list') {
		display('list');
	} else if (localStorage.getItem('display') == 'grid') {
		display('grid');
	} else {
		display('grid');
	}

	$('#list-view').click(function() {
		$('#content .product-grid > .clearfix').remove();

		$('#content .row > .product-grid').attr('class', 'product-layout product-list col-xs-12');
		$('#grid-view').removeClass('active');
		$('#list-view').addClass('active');

		localStorage.setItem('display-another', 'list');
	});

	// Product Grid
	$('#grid-view').click(function() {
		// What a shame bootstrap does not take into account dynamically loaded columns
		var cols = $('#column-right, #column-left').length;

		if (cols == 2) {
			$('#content .product-list').attr('class', 'product-layout product-grid col-lg-6 col-md-6 col-sm-12 col-xs-12');
		} else if (cols == 1) {
			$('#content .product-list').attr('class', 'product-layout product-grid col-lg-4 col-md-4 col-sm-6 col-xs-12');
		} else {
			$('#content .product-list').attr('class', 'product-layout product-grid col-lg-3 col-md-3 col-sm-6 col-xs-12');
		}

		$('#list-view').removeClass('active');
		$('#grid-view').addClass('active');

		localStorage.setItem('display-another', 'grid');
	});

	if (localStorage.getItem('display-another') == 'list') {
		$('#list-view').trigger('click');
		$('#list-view').addClass('active');
	} else {
		$('#grid-view').trigger('click');
		$('#grid-view').addClass('active');
	}


	// Checkout
	$(document).on('keydown', '#collapse-checkout-option input[name=\'email\'], #collapse-checkout-option input[name=\'password\']', function(e) {
		if (e.keyCode == 13) {
			$('#collapse-checkout-option #button-login').trigger('click');
		}
	});

	// tooltips on hover
	$('[data-toggle=\'tooltip\']').tooltip({container: 'body'});

	// Makes tooltips work on ajax generated content
	$(document).ajaxStop(function() {
		$('[data-toggle=\'tooltip\']').tooltip({container: 'body'});
	});

	$('.mobile-toggle, #mobile-menu-overlay').on('click', function (e) {
$('body').toggleClass('opened-menu');
e.preventDefault();
});

	/* toggle side menu + mobile menu sub menus */
	$('.accordion-menu').find('.arrow').on('click', function(e) {

		if ($(this).closest('li').find('ul').length) {
			$(this).closest('li').children('ul').slideToggle(350, function () {
				$(this).closest('li').toggleClass('open');
			});
			e.preventDefault();
		} else {
			return;
		}

	});
});

// Cart add remove functions

function addTocart(product_id){
	var product_id = product_id;
	var qty = $('#input-quantity-'+product_id).val();
	var location = $('#location-'+product_id).attr('href');
	$.ajax({
		url: 'index.php?route=checkout/cart/add',
		type: 'post',
		data: 'product_id='+product_id+'&quantity='+qty,
		dataType: 'json',
		beforeSend: function() {
			$('#button-cart').button('loading');
		},
		complete: function() {
			$('#button-cart').button('reset');
		},
		success: function(json) {
			$('.alert, .text-danger').remove();
			$('.form-group').removeClass('has-error');

			if (json['error']) {
				if (json['error']['option']) {
					window.location = location;
				}

				if (json['error']['recurring']) {
					window.location = location;
				}

				// Highlight any found errors
				$('.text-danger').parent().addClass('has-error');
			}

			if (json['success']) {
				$('.breadcrumb').after('<div class="alert alert-success">' + json['success'] + '<button type="button" class="close" data-bs-dismiss="alert">&times;</button></div>');

				$('#cart > button').html('<i class="fa fa-shopping-cart"></i> ' + json['total']);

				$('html, body').animate({ scrollTop: 0 }, 'slow');

				$('#cart > ul').load('index.php?route=common/cart/info ul li');
			}
		},
		error: function(xhr, ajaxOptions, thrownError) {
			alert(thrownError + "\r\n" + xhr.statusText + "\r\n" + xhr.responseText);
		}
	});
}


window.cart = {
	'add': function(product_id, quantity) {
		$.ajax({
			url: 'index.php?route=checkout/cart/add',
			type: 'post',
			data: 'product_id=' + product_id + '&quantity=' + (typeof(quantity) != 'undefined' ? quantity : 1),
			dataType: 'json',
			beforeSend: function() {
				$('#cart > button').button('loading');
			},
			complete: function() {
				$('#cart > button').button('reset');
			},
			success: function(json) {
				$('.alert, .text-danger').remove();

				if (json['redirect']) {
					location = json['redirect'];
				}

				if (json['success']) {
						//ocmodification -- TabulkaDopravy
						var isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent) ? true : false;


					if(json['tabulkaDopravy'] && isMobile == false) {
                        $("#modal-window").html(json['tabulkaDopravy']);
                        $("#modal-window").modal('show');
                    } else {
						$('.main .container').before('<div class="row white"><div class="alert alert-success"><i class="fa fa-check-circle"></i> ' + json['success'] + ' <button type="button" class="close" data-bs-dismiss="alert">&times;</button></div></div>');
					}

					//ocmodification -- TabulkaDopravy

					// Need to set timeout otherwise it wont update the total
					setTimeout(function () {
						$('[id=cart-icon]').each(function() {
						  $(this).html('<i class="bi bi-cart2 fs-4"></i><span id="cart-total" class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-success">' + json['total'] + '</span>');
						});
					  }, 100);

					$('html, body').animate({ scrollTop: 0 }, 'slow');

					$('.mini-cart .offcanvas-body').load('index.php?route=common/cart/info .offcanvas-body');
				}
			},
			error: function(xhr, ajaxOptions, thrownError) {
				alert(thrownError + "\r\n" + xhr.statusText + "\r\n" + xhr.responseText);
			}
		});
	},
	'update': function(key, quantity) {
		$.ajax({
			url: 'index.php?route=checkout/cart/edit',
			type: 'post',
			data: 'key=' + key + '&quantity=' + (typeof(quantity) != 'undefined' ? quantity : 1),
			dataType: 'json',
			beforeSend: function() {
				$('#cart > button').button('loading');
			},
			complete: function() {
				$('#cart > button').button('reset');
			},
			success: function(json) {
				// Need to set timeout otherwise it wont update the total
				setTimeout(function () {
					$('[id=cart-icon]').each(function() {
					  $(this).html('<i class="bi bi-cart2 fs-4"></i><span id="cart-total" class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-success">' + json['total'] + '</span>');
					});
				  }, 100);


				if (getURLVar('route') == 'checkout/cart' || getURLVar('route') == 'checkout/checkout') {
					location = 'index.php?route=checkout/cart';
				} else {
					$('.mini-cart .offcanvas-body').load('index.php?route=common/cart/info .offcanvas-body');
				}
			},
			error: function(xhr, ajaxOptions, thrownError) {
				alert(thrownError + "\r\n" + xhr.statusText + "\r\n" + xhr.responseText);
			}
		});
	},
	'remove': function(key) {
		$.ajax({
			url: 'index.php?route=checkout/cart/remove',
			type: 'post',
			data: 'key=' + key,
			dataType: 'json',
			beforeSend: function() {
				$('#cart > button').button('loading');
			},
			complete: function() {
				$('#cart > button').button('reset');
			},
			success: function(json) {
				// Need to set timeout otherwise it wont update the total
				// Need to set timeout otherwise it wont update the total
				setTimeout(function () {
					$('[id=cart-icon]').each(function() {
					  $(this).html('<i class="bi bi-cart2 fs-4"></i><span id="cart-total" class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-success">' + json['total'] + '</span>');
					});
				  }, 100);

				if (getURLVar('route') == 'checkout/cart' || getURLVar('route') == 'checkout/checkout') {
					location = 'index.php?route=checkout/cart';
				} else {
					$('.mini-cart .offcanvas-body').load('index.php?route=common/cart/info .offcanvas-body');
				}
			},
			error: function(xhr, ajaxOptions, thrownError) {
				alert(thrownError + "\r\n" + xhr.statusText + "\r\n" + xhr.responseText);
			}
		});
	}
}

window.voucher = {
	'add': function() {

	},
	'remove': function(key) {
		$.ajax({
			url: 'index.php?route=checkout/cart/remove',
			type: 'post',
			data: 'key=' + key,
			dataType: 'json',
			beforeSend: function() {
				$('#cart > button').button('loading');
			},
			complete: function() {
				$('#cart > button').button('reset');
			},
			success: function(json) {
				// Need to set timeout otherwise it wont update the total
				setTimeout(function () {
					$('#cart > button').html('<span id="cart-total"><i class="fa fa-shopping-cart"></i> ' + json['total'] + '</span>');
				}, 100);

				if (getURLVar('route') == 'checkout/cart' || getURLVar('route') == 'checkout/checkout') {
					location = 'index.php?route=checkout/cart';
				} else {
					$('#cart > ul').load('index.php?route=common/cart/info ul li');
				}
			},
			error: function(xhr, ajaxOptions, thrownError) {
				alert(thrownError + "\r\n" + xhr.statusText + "\r\n" + xhr.responseText);
			}
		});
	}
}

window.wishlist = {
	'add': function(product_id) {
		$.ajax({
			url: 'index.php?route=account/wishlist/add',
			type: 'post',
			data: 'product_id=' + product_id,
			dataType: 'json',
			success: function(json) {
				$('.main .alert').parent().remove();

				if (json['redirect']) {
					location = json['redirect'];
				}

				if (json['success']) {
					$('.main .container').before('<div class="container"><div class="alert alert-success"><i class="fa fa-check-circle"></i> ' + json['success'] + ' <button type="button" class="close" data-bs-dismiss="alert">&times;</button></div></div>');
				}

				$('html, body').animate({ scrollTop: 0 }, 'slow');
			},
			error: function(xhr, ajaxOptions, thrownError) {
				alert(thrownError + "\r\n" + xhr.statusText + "\r\n" + xhr.responseText);
			}
		});
	},
	'remove': function() {

	}
}

window.compare = {
	'add': function(product_id) {
		$.ajax({
			url: 'index.php?route=product/compare/add',
			type: 'post',
			data: 'product_id=' + product_id,
			dataType: 'json',
			success: function(json) {
				$('.main .alert').parent().remove();

				if (json['success']) {
					$('.main .container').before('<div class="container"><div class="alert alert-success"><i class="fa fa-check-circle"></i> ' + json['success'] + ' <button type="button" class="close" data-bs-dismiss="alert">&times;</button></div></div>');

					$('html, body').animate({ scrollTop: 0 }, 'slow');
				}
			},
			error: function(xhr, ajaxOptions, thrownError) {
				alert(thrownError + "\r\n" + xhr.statusText + "\r\n" + xhr.responseText);
			}
		});
	},
	'remove': function() {

	}
}

/* Agree to Terms */
$(document).delegate('.agree', 'click', function(e) {
	e.preventDefault();

	$('#modal-agree').remove();

	var element = this;

	$.ajax({
		url: $(element).attr('href'),
		type: 'get',
		dataType: 'html',
		success: function(data) {
			html  = '<div id="modal-agree" class="modal">';
			html += '  <div class="modal-dialog">';
			html += '    <div class="modal-content">';
			html += '      <div class="modal-header">';
			html += '        <button type="button" class="close" data-bs-dismiss="modal" aria-hidden="true">&times;</button>';
			html += '        <h4 class="modal-title">' + $(element).text() + '</h4>';
			html += '      </div>';
			html += '      <div class="modal-body">' + data + '</div>';
			html += '    </div';
			html += '  </div>';
			html += '</div>';

			$('body').append(html);

			$('#modal-agree').modal('show');
		}
	});
});

// Autocomplete */
(function($) {
	$.fn.autocomplete = function(option) {
		return this.each(function() {
			this.timer = null;
			this.items = new Array();

			$.extend(this, option);

			$(this).attr('autocomplete', 'off');

			// Focus
			$(this).on('focus', function() {
				this.request();
			});

			// Blur
			$(this).on('blur', function() {
				setTimeout(function(object) {
					object.hide();
				}, 200, this);
			});

			// Keydown
			$(this).on('keydown', function(event) {
				switch(event.keyCode) {
					case 27: // escape
						this.hide();
						break;
					default:
						this.request();
						break;
				}
			});

			// Click
			this.click = function(event) {
				event.preventDefault();

				value = $(event.target).parent().attr('data-value');

				if (value && this.items[value]) {
					this.select(this.items[value]);
				}
			}

			// Show
			this.show = function() {
				var pos = $(this).position();

				$(this).siblings('ul.dropdown-menu').css({
					top: pos.top + $(this).outerHeight(),
					left: pos.left
				});

				$(this).siblings('ul.dropdown-menu').show();
			}

			// Hide
			this.hide = function() {
				$(this).siblings('ul.dropdown-menu').hide();
			}

			// Request
			this.request = function() {
				clearTimeout(this.timer);

				this.timer = setTimeout(function(object) {
					object.source($(object).val(), $.proxy(object.response, object));
				}, 200, this);
			}

			// Response
			this.response = function(json) {
				html = '';

				if (json.length) {
					for (i = 0; i < json.length; i++) {
						this.items[json[i]['value']] = json[i];
					}

					for (i = 0; i < json.length; i++) {
						if (!json[i]['category']) {
							html += '<li data-value="' + json[i]['value'] + '"><a href="#">' + json[i]['label'] + '</a></li>';
						}
					}

					// Get all the ones with a categories
					var category = new Array();

					for (i = 0; i < json.length; i++) {
						if (json[i]['category']) {
							if (!category[json[i]['category']]) {
								category[json[i]['category']] = new Array();
								category[json[i]['category']]['name'] = json[i]['category'];
								category[json[i]['category']]['item'] = new Array();
							}

							category[json[i]['category']]['item'].push(json[i]);
						}
					}

					for (i in category) {
						html += '<li class="dropdown-header">' + category[i]['name'] + '</li>';

						for (j = 0; j < category[i]['item'].length; j++) {
							html += '<li data-value="' + category[i]['item'][j]['value'] + '"><a href="#">&nbsp;&nbsp;&nbsp;' + category[i]['item'][j]['label'] + '</a></li>';
						}
					}
				}

				if (html) {
					this.show();
				} else {
					this.hide();
				}

				$(this).siblings('ul.dropdown-menu').html(html);
			}

			$(this).after('<ul class="dropdown-menu"></ul>');
			$(this).siblings('ul.dropdown-menu').delegate('a', 'click', $.proxy(this.click, this));

		});
	}
})(window.jQuery);

//ocmodification -- darky //
window.darek = {
  'add': function(product_id, quantity) {
    $.ajax({
      url: 'index.php?route=extension/module/darky/add',
      type: 'post',
      data: 'product_id=' + product_id + '&quantity=' + (typeof(quantity) != 'undefined' ? quantity : 1),
      dataType: 'json',
      beforeSend: function() {
        $('#cart > button').button('loading');
      },
      complete: function() {
        $('#cart > button').button('reset');
      },
      success: function(json) {
        $('.alert, .text-danger').remove();

        if (json['redirect']) {
          location = json['redirect'];
        }

        if (json['success']) {

			$('.main .container').before('<div class="container"><div class="alert alert-success"><i class="fa fa-check-circle"></i> ' + json['success'] + ' <button type="button" class="close" data-bs-dismiss="alert">&times;</button></div></div>');

          $('html, body').animate({ scrollTop: 0 }, 'slow');


					if (getURLVar('route') == 'checkout/cart' || getURLVar('route') == 'checkout/checkout') {
						location = 'index.php?route=checkout/cart';
					} else {
						$('#cart > ul').load('index.php?route=common/cart/info ul li');
					}
        }
      },
      error: function(xhr, ajaxOptions, thrownError) {
        alert(thrownError + "\r\n" + xhr.statusText + "\r\n" + xhr.responseText);
      }
    });
  }
}


document.addEventListener("DOMContentLoaded", function () {
	$("#filter .collapse").on("shown.bs.collapse", function () {
		localStorage.setItem("coll_" + this.dataset.filter, true);
	});

	$("#filter .collapse").on("hidden.bs.collapse", function () {
		localStorage.removeItem("coll_" + this.dataset.filter);
	});

	$("#filter .collapse").each(function () {
		if (localStorage.getItem("coll_" + this.dataset.filter) === "true") {
			$(this).collapse("show");
		}
		else {
			$(this).collapse("hide");
		}
	});

	const filters = document.querySelectorAll("#filter");

	filters.forEach(function (filter, index) {
		const parent = filter.parentElement;
		const parentDisplay = window.getComputedStyle(parent).display;
		const filterGroups = filter.querySelectorAll('[id^="filter-group"]')

		filterGroups.forEach(group => {
			const numChildElements = group.children.length;


			if (numChildElements > 7 && (group.parentElement.classList.contains('image-filter') == false)) {
				group.classList.add('grid-container');
			}
		})

		if(parentDisplay === 'block') {
			var action = filter.querySelector("input[name='action']").value
			const checkboxes = filter.querySelectorAll("div.checkbox");

			checkboxes.forEach(function (checkbox, index) {
				const filterImage = checkbox.querySelector("img") ? checkbox.querySelector("img") : null;
				checkbox.addEventListener("click", function () {

					const input = checkbox.querySelector('input[type="checkbox"]')
					const selected = !input.checked;


					if (selected) {
						input.setAttribute("checked", true)
						if(filterImage != null) {
							filterImage.classList.add("selected");
						}
					} else {
						input.removeAttribute("checked")
						if(filterImage != null) {
							filterImage.classList.remove("selected");
						}
					}


					const filterValue = [];
					filter.querySelectorAll("input[name^='filter']:checked").forEach(function (element) {
						filterValue.push(element.value);
					});


					location = action+'&filter=' + filterValue.join(',')+'#filter';
				});
			})
		}
	});
});
